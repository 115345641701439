import React, { useEffect } from "react";
import { Box, Button, Card, Checkbox, Flex, Heading, IconButton, Link, ScrollArea, Separator, Spinner, Strong, Text, TextField } from "@radix-ui/themes";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

// import component 👇
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";
import { trpc } from "@/utils/trpc";
import { useUser } from "@/hooks/useUser";
import { RenderableConversation } from "@/pages/api/trpc/conversations/conversationProcedures";
import Image from "next/image";
import { imageKitLoader } from "@/pages/app/home/imageKitLoader";
import { CharacterAvatarRow } from "@/pages/app/home/characterAvatarRow";
import { toFrontendScenario } from "@/pages/api/trpc/scenarios/toFrontendScenario";
import { useRouterUtils } from "@/app/routerUtils";
import { useDebounce } from "@uidotdev/usehooks";
import { useAnalytics } from "@/analyticsContext";
const groupConversationsByAge = (conversations: RenderableConversation[], groups: number[]): RenderableConversation[][] => {
  const now = Date.now();
  const grouped: RenderableConversation[][] = groups.map(() => []);
  for (const conversation of conversations) {
    const lastModified = conversation.conversation.updatedAt ?? conversation.conversation.createdAt;
    const age = now - lastModified.getTime();

    // Find the appropriate group for this conversation
    const groupIndex = groups.findIndex(group => age <= group);
    if (groupIndex !== -1) {
      grouped[groupIndex].push(conversation);
    } else {
      // If the conversation is older than all groups, add it to a separate group
      if (!grouped[grouped.length - 1]) grouped.push([]);
      grouped[grouped.length - 1].push(conversation);
    }
  }
  return grouped;
};
function StoryDrawerListItem({
  conversation,
  onClick
}: {
  conversation: RenderableConversation;
  onClick?: () => void;
}) {
  return <Card asChild size={"1"} variant={"ghost"} className={"w-full h-16"} style={{
    margin: 0,
    filter: "none",
    position: "relative",
    backdropFilter: "none !important"
  }} data-sentry-element="Card" data-sentry-component="StoryDrawerListItem" data-sentry-source-file="storyDrawer.tsx">
      <a href="#" onClick={onClick}>
        <Box style={{
        top: "120%",
        left: 0,
        right: 0,
        bottom: 0,
        position: "absolute"
      }} data-sentry-element="Box" data-sentry-source-file="storyDrawer.tsx">
          <Box width={"100%"} height={"100%"} data-sentry-element="Box" data-sentry-source-file="storyDrawer.tsx">
            <CharacterAvatarRow scenario={toFrontendScenario(conversation)} loadEarly={true} size={"90px"} data-sentry-element="CharacterAvatarRow" data-sentry-source-file="storyDrawer.tsx" />
          </Box>
        </Box>
        <Flex className={"h-full w-full z-10 -px-4"} align={"center"} data-sentry-element="Flex" data-sentry-source-file="storyDrawer.tsx">
          <Text size={"3"} className={"z-10 w-full line-clamp-2 px-4"} align={"right"} style={{
          textShadow: "0px 0px 2px var(--mauve-2), 0px 0px 4px var(--mauve-2), 0px 0px 10px var(--mauve-2), 0px 0px 10px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2),0px 0px 2px var(--mauve-2), 0px 0px 4px var(--mauve-2), 0px 0px 10px var(--mauve-2), 0px 0px 10px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2)"
        }} data-sentry-element="Text" data-sentry-source-file="storyDrawer.tsx">
            {conversation.conversation.name ?? conversation.scenario.title}
          </Text>
        </Flex>
        <Image loader={imageKitLoader} fill className={"object-cover absolute inset-0 w-full h-full"} loading={"eager"} sizes="150px, (max-width: 768px) 500px" src={conversation.scenario.previewImage} alt={conversation.scenario.background ?? `A sketch for a story named ${conversation.scenario.title}`} style={{
        mask: "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, .2))"
      }} data-sentry-element="Image" data-sentry-source-file="storyDrawer.tsx" />
      </a>
    </Card>;
}
export function StoryDrawer() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [selectedStories, setSelectedStories] = React.useState<RenderableConversation[]>([]);
  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce(search, 300);
  const analytics = useAnalytics();
  const toggleDrawer = () => {
    setIsOpen(prevState => {
      if (prevState) {
        setEditMode(false);
      }
      analytics.capture("storyDrawer.storyDrawerToggled", {
        open: !prevState
      });
      return !prevState;
    });
  };
  const stories = trpc.conversations.getConversations.useQuery({
    searchTerm: debouncedSearch
  });
  const timePeriods: [string, number][] = search ? [["All Time", 10000000000]] : [["Today", 86400000], ["Yesterday", 172800000], ["Previous 7 Days", 604800000], ["This Month", 2592000000]];
  const storiesGrouped = stories.data ? groupConversationsByAge(stories.data, timePeriods.map(([, age]) => age)) : [];
  const {
    openStory
  } = useRouterUtils();
  const deleteStories = trpc.conversations.deleteConversations.useMutation();
  const getRowHeight = (index: number) => {
    let currentIndex = 0;
    for (let i = 0; i < storiesGrouped.length; i++) {
      if (index === currentIndex) {
        return 32; // 32px for header
      }
      currentIndex++;
      if (index < currentIndex + storiesGrouped[i].length) {
        return 72; // 5.25rem for story item
      }
      currentIndex += storiesGrouped[i].length;
    }
    return 72; // Default to 5.25rem if something goes wrong
  };
  const Row = React.memo(({
    index,
    style
  }) => {
    let content;
    let isHeading = false;
    let groupIndex = 0;
    let itemIndex = -1;
    for (let i = 0; i < storiesGrouped.length; i++) {
      if (index === 0) {
        isHeading = true;
        groupIndex = i;
        break;
      }
      index--;
      if (index < storiesGrouped[i].length) {
        groupIndex = i;
        itemIndex = index;
        break;
      }
      index -= storiesGrouped[i].length;
    }
    if (isHeading) {
      content = <Heading size={"3"}>{timePeriods[groupIndex][0]}</Heading>;
    } else {
      const conversation = storiesGrouped[groupIndex][itemIndex];
      content = <Flex align={"center"} gap={"2"}>
          {editMode && <Checkbox size="3" checked={selectedStories.some(selected => selected.conversation.id === conversation.conversation.id)} onCheckedChange={isChecked => {
          if (isChecked) {
            setSelectedStories(prevState => [...prevState, conversation]);
          } else {
            setSelectedStories(prevState => prevState.filter(selected => selected.conversation.id !== conversation.conversation.id));
          }
        }} variant={"classic"} color={"red"} />}
          <StoryDrawerListItem conversation={conversation} onClick={async () => {
          await openStory(conversation.conversation.id);
          setIsOpen(false);
        }} />
        </Flex>;
    }
    return <div style={style}>{content}</div>;
  });
  const itemCount = React.useMemo(() => {
    return storiesGrouped.reduce((acc, group) => acc + group.length + 1, 0);
  }, [storiesGrouped]);
  const listRef = React.useRef<List>(null);
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
  }, [isOpen]);
  return <>
      <Link onClick={toggleDrawer} underline={"always"} color={"gray"} highContrast className={"cursor-pointer"} size={"3"} aria-label="My stories" href="#" data-sentry-element="Link" data-sentry-source-file="storyDrawer.tsx">
        My&nbsp;stories
      </Link>
      <Drawer open={isOpen} onClose={toggleDrawer} direction="right" customIdSuffix="story-drawer" lockBackgroundScroll={true} style={{
      backgroundColor: "transparent",
      boxShadow: "none",
      display: isOpen ? "block" : "none",
      paddingTop: "env(safe-area-inset-top)",
      paddingBottom: "env(safe-area-inset-bottom)",
      zIndex: 1000000
    }} data-sentry-element="Drawer" data-sentry-source-file="storyDrawer.tsx">
        {isOpen ? <Card className={"h-[100%] max-h-[100svh]"}>
            <Flex direction={"column"} height={"100%"} gap={"3"}>
              <div style={{
            flex: 1,
            minHeight: 0
          }}>
                <AutoSizer>
                  {({
                height,
                width
              }) => <ScrollArea style={{
                width,
                height
              }}>
                      <List height={height} itemCount={itemCount} itemSize={getRowHeight} width={width} ref={listRef} style={{
                  "::-webkit-scrollbar": {
                    width: "8px"
                  },
                  "::-webkit-scrollbar-track": {
                    background: "transparent",
                    borderRadius: "4px"
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "4px"
                  },
                  "::-webkit-scrollbar-thumb:hover": {
                    background: "#555"
                  },
                  scrollbarWidth: "thin",
                  scrollbarColor: "#888 transparent"
                }}>
                        {Row}
                      </List>
                    </ScrollArea>}
                </AutoSizer>
              </div>
              <Separator size={"3"} />
              <TextField.Root placeholder="Search your stories" onChange={e => setSearch(e.target.value)} value={search} onFocus={() => {
            analytics.capture("storyDrawer.searchFocused", {});
          }} />
              {selectedStories.length == 0 && <Button variant={"outline"} onClick={() => {
            analytics.capture("storyDrawer.editModeToggled", {
              open: !editMode
            });
            setEditMode(prevState => !prevState);
          }}>
                  {editMode ? "Cancel" : "Edit Stories"}
                </Button>}
              {selectedStories.length > 0 && <Flex width={"100%"} gap={"2"}>
                  <Button className={"flex-grow"} variant={"soft"} loading={deleteStories.isPending} onClick={async () => {
              analytics.capture("storyDrawer.deleteStory", {
                storyCount: selectedStories.length
              });
              await deleteStories.mutateAsync({
                conversationIds: selectedStories.map(s => s.conversation.id)
              });
              await stories.refetch();
              setSelectedStories([]);
              setEditMode(false);
              if (listRef.current) {
                listRef.current.resetAfterIndex(0);
              }
            }} color={"tomato"}>
                    Delete {selectedStories.length} Stories
                  </Button>
                  <Button>Cancel</Button>
                </Flex>}
            </Flex>
          </Card> : null}
      </Drawer>
    </>;
}