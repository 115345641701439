import { RenderableUserProfile } from "@/pages/api/trpc/user/renderableUserProfile";
import { generateReferralCode } from "@/shared/referral";
import { Flex, Text, Heading, Box, Progress } from "@radix-ui/themes";
import { toast } from "sonner";
export function ReferralMessage({
  userProfile
}: {
  userProfile: RenderableUserProfile;
}) {
  return <Flex direction={"column"} align={"center"} gap={"3"} data-sentry-element="Flex" data-sentry-component="ReferralMessage" data-sentry-source-file="referralMessage.tsx">
      <Heading data-sentry-element="Heading" data-sentry-source-file="referralMessage.tsx">Help us share Spellbound with new users!</Heading>
      <Text data-sentry-element="Text" data-sentry-source-file="referralMessage.tsx">
        Refer users by sharing your unique link{" "}
        <Text className="cursor-pointer underline font-bold" onClick={() => {
        navigator.clipboard.writeText(`${window.origin}?ref=${generateReferralCode(userProfile.id!)}`);
        toast.success("Copied your referral link to clipboard");
      }} data-sentry-element="Text" data-sentry-source-file="referralMessage.tsx">
          here
        </Text>{" "}
        or by sharing links to scenarios. <br />
        <ul className="list-disc list-inside">
          <li>
            You'll get 10 quills for each user that joins and sends a message.
          </li>
          <li>
            You'll also get 50 bonus quills if you refer 5 users by the end of
            your first week.
          </li>
          <li>
            Make sure the scenario link has{" "}
            <Text as="span" className="font-bold" data-sentry-element="Text" data-sentry-source-file="referralMessage.tsx">
              ref?={generateReferralCode(userProfile.id!)}
            </Text>{" "}
            or you won't get the bonus quills.
          </li>
        </ul>
      </Text>
      <Box width={"100%"} maxWidth={"20rem"} data-sentry-element="Box" data-sentry-source-file="referralMessage.tsx">
        <Progress value={Math.max(userProfile.referralCount / 5 * 100, 1)} size="3" data-sentry-element="Progress" data-sentry-source-file="referralMessage.tsx" />
      </Box>
      <Text data-sentry-element="Text" data-sentry-source-file="referralMessage.tsx">You've referred {userProfile.referralCount} out of 5 users.</Text>
    </Flex>;
}