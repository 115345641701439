import {
  AnyPgColumn,
  bigint,
  bigserial,
  boolean,
  doublePrecision,
  halfvec,
  index,
  integer,
  jsonb,
  pgEnum,
  pgMaterializedView,
  pgTable,
  primaryKey,
  real,
  serial,
  text,
  timestamp,
  varchar,
  vector,
} from "drizzle-orm/pg-core";
import { and, eq, gte, lt, relations, sql } from "drizzle-orm";
import { tsvector } from "./tsVector";
import { NarrativeInstruction } from "@/db/narrativeInstruction";
import { DiscriminatedWebhookPayload } from "lemonsqueezy-webhooks";

export const subscriptionTierEnum = pgEnum("subscription_tier", [
  "legacy",
  "basic",
  "plus",
  "ultra",
  "unlimited",
  "mixtral",
]);
export const genderEnum = pgEnum("gender", ["male", "female", "non-binary"]);
export const voiceEnum = pgEnum("narration_voice", [
  "Noah",
  "Mila",
  "Oliver",
  "Sandra",
  "Henry",
  "Sophia",
]);

export const fontSizeEnum = pgEnum("font_size", [
  "smallest",
  "small",
  "medium",
  "large",
  "largest",
]);
export const voteEnum = pgEnum("vote", ["upvote", "downvote"]);
export const conversationModeEnum = pgEnum("conversation_mode", [
  "chat",
  "story",
]);
export const fontEnum = pgEnum("font", [
  "mate",
  "helvetica",
  "jost",
  "karla",
  "bionic",
  "opendyslexic",
]);
export const storyStyleEnum = pgEnum("story_style", [
  "plain",
  "custom",
  "eloquent",
  "terse",
  "slang",
  "archaic",
  "humanCasual",
]);
export const povEnum = pgEnum("story_pov", ["second", "third", "first"]);
export const languageEnum = pgEnum("language", [
  "en-US",
  "pt-BR",
  "es-LA",
  "zh-CN",
  "ko-KR",
  "fil-PH",
  "fr-FR",
]);
export const visibilityEnum = pgEnum("visibility", ["public", "private"]);
export const scenarioTypeEnum = pgEnum("scenario_type", [
  "counsel",
  "intimacy",
  "confrontation",
  "adventure",
  "custom",
]);
export const creativityLevelEnum = pgEnum("creativity_level", [
  "low",
  "medium",
  "high",
  "extreme",
  "mixtral",
  "control",
]);
export const themeEnum = pgEnum("ui_theme", [
  "gray",
  "gold",
  "bronze",
  "brown",
  "yellow",
  "amber",
  "orange",
  "tomato",
  "red",
  "ruby",
  "crimson",
  "pink",
  "plum",
  "purple",
  "violet",
  "iris",
  "indigo",
  "blue",
  "cyan",
  "teal",
  "jade",
  "green",
  "grass",
  "lime",
  "mint",
  "sky",
]);
export const sexEnum = pgEnum("sex", ["male", "female", "intersex"]);

export enum CharacterCategories {
  VideoGameCharacters = "VIDEO_GAME_CHARACTERS",
  MusicAndBands = "MUSIC_AND_BANDS",
  MoviesAndTheater = "MOVIES_AND_THEATER",
  TvShows = "TV_SHOWS",
  CartoonsAndComics = "CARTOONS_AND_COMICS",
  BooksAndLiterature = "BOOKS_AND_LITERATURE",
  AnimeAndManga = "ANIME_AND_MANGA",
  CelebritiesAndRealPeople = "CELEBRITIES_AND_REAL_PEOPLE",
  OtherMedia = "OTHER_MEDIA",
}

export const conversations = pgTable(
  "conversations",
  {
    id: serial("id").primaryKey(),

    creatorId: integer("creator_id")
      .notNull()
      .references(() => users.id),
    selectedPersonaId: integer("selected_persona_id").references(
      () => userPersonas.id,
    ),
    name: text("name"),
    settingEnableThoughts: boolean("setting_enable_thoughts"),
    settingControlMode: boolean("setting_control_mode"),
    settingPrefixMode: boolean("setting_prefix_mode"),
    settingSupercharged: boolean("setting_supercharged"),
    settingConversationMode: conversationModeEnum("setting_conversation_mode"),
    scenarioId: integer("scenario_id")
      .notNull()
      .references(() => scenarios.id),
    relationshipSummary: text("relationship_summary"), //TODO: Likely remove, redundant with scenario
    visibility: visibilityEnum("visibility").default("public"),
    isDeleted: boolean("is_deleted").default(false).notNull(),
    settingShowFormatting: boolean("setting_show_formatting")
      .default(true)
      .notNull(),
    settingGenerateImages: boolean("setting_generate_images")
      .default(true)
      .notNull(),
    settingNarrationVoice: voiceEnum("setting_narration_voice"),
    settingWritingPov: povEnum("setting_writing_pov"),
    settingStoryNotes: text("setting_story_notes"),
    settingStoryStyle: storyStyleEnum("setting_story_style").default("plain"),
    settingCustomStoryStyle: text("setting_custom_story_style"),
    settingEnableRolls: boolean("setting_enable_rolls"),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at"),
    backgroundImage: text("background_image"),
    downgraded: boolean("downgraded").default(false).notNull(),
    downgradedAt: timestamp("downgraded_at"),
  },
  (table) => ({
    creatorIndex: index("conversation_creator_idx").on(table.creatorId),
  }),
);

export const users = pgTable(
  "users",
  {
    id: serial("id").primaryKey().notNull(),
    username: text("username").unique().notNull(),
    disposableEmailDetected: boolean("disposable_email_detected").default(
      false,
    ),
    anonymousUid: text("anonymous_uid"),
    premiumUntil: timestamp("premium_until"),
    blockSubscriptionUntil: timestamp("block_subscription_until"),
    supabaseUid: varchar("supabase_uid", { length: 200 }).unique(),
    preferredName: text("preferred_name").notNull().default("Silk"),
    preferredFontSize: fontSizeEnum("preferred_font_size")
      .notNull()
      .default("medium"),
    personaDescription: text("persona_description"),
    defaultSex: sexEnum("preferred_sex"),
    mostRecentFingerprint: text("most_recent_fingerprint"),
    mostRecentIpAddress: text("most_recent_ip_address"),
    defaultGender: genderEnum("preferred_gender"),
    email: text("email").unique(),
    subscriberEmail: text("subscriber_email").unique(),
    phone: text("phone").unique(),
    preferredFont: fontEnum("preferred_font").default("mate").notNull(),
    preferredTheme: themeEnum("preferred_theme"),
    preferredPov: povEnum("preferred_pov").default("second").notNull(),
    preferredCreativity: creativityLevelEnum("preferred_creativity")
      .default("high")
      .notNull(),
    preferredLanguage: languageEnum("preferred_language")
      .default("en-US")
      .notNull(),
    quills: integer("quill_count").default(0).notNull(),
    dailyQuills: integer("daily_quill_count").default(0).notNull(),
    monthlyQuills: integer("monthly_quill_count").default(0).notNull(),
    paidQuills: integer("paid_quill_count").default(0).notNull(),
    freeQuills: integer("free_quill_count").default(0).notNull(),
    referralQuills: integer("referral_quill_count").default(0).notNull(),
    totalQuills: integer("total_quill_count").default(0).notNull(),
    allowAdult: boolean("allow_adult").default(false).notNull(),
    useTurbo: boolean("use_turbo").default(false).notNull(),
    role: text("role").$type<"admin" | "user">().default("user"),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at"),
    lastLoginAt: timestamp("last_login_at"),
    highUsage: boolean("high_usage").default(false).notNull(),
    proxyUsageDetected: boolean("proxy_usage_detected"),
    duplicateAccountDetected: boolean("duplicate_account_detected")
      .default(false)
      .notNull(),
    processingLevel: boolean("processing_level").default(false).notNull(),
    defaultPersonaId: integer("default_persona_id").references(
      (): AnyPgColumn => userPersonas.id,
    ),
    referredBy: integer("referred_by").references((): AnyPgColumn => users.id),
  },
  (table) => ({
    supabaseUidIndex: index("supabase_uid_index").on(table.supabaseUid),
  }),
);

export const usersRelations = relations(users, ({ one, many }) => ({
  invitee: one(users, {
    fields: [users.referredBy],
    references: [users.id],
  }),
  referralsReceived: many(userReferral, { relationName: "referredUser" }),
  referralsMade: many(userReferral, { relationName: "referredByUser" }),
}));
export const scenarios = pgTable(
  "scenarios",
  {
    id: bigserial("id", { mode: "number" }).primaryKey(),
    creatorId: integer("creator_id").references(() => users.id),
    title: text("title").notNull(),
    description: text("description").notNull(),
    background: text("background").notNull(),
    tagline: text("tagline").notNull(),
    callToAction: text("call_to_action").notNull(),
    introText: text("intro_text").notNull(),
    startingScene: text("starting_scene").notNull(),
    characterDescriptions: text("character_descriptions").notNull(),
    previewImage: text("preview_image").notNull(),
    public: boolean("is_public").notNull(),
    canHighlight: boolean("can_highlight").notNull(),
    messageCount: integer("message_count").notNull(),
    canSearch: boolean("can_search").notNull(),
    isAdult: boolean("is_adult").notNull(),
    context: text("context").notNull(),
    tags: text("tags").array(),
    goalPoints: text("goal_points").array(),
    scenarioType: scenarioTypeEnum("scenario_type").notNull(),
    passesModeration: boolean("passes_moderation"),
    continuationOptions: text("continuation_options").array(),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at"),
    shortDescription: text("short_description"),
    ageEstimate: integer("age_estimate"),
    hasGraphicViolence: boolean("has_graphic_violence"),
    isUnderage: boolean("is_underage"),
    hasInnuendo: boolean("has_innuendo"),
    isUnsettling: boolean("is_unsettling"),
    isHatePromotion: boolean("is_hate_promotion"),
    isSexual: boolean("is_sexual"),
    isSuitable: boolean("is_suitable"),
    isUnsafe: boolean("is_unsafe"),
    isCelebrity: boolean("is_celebrity"),
    reasonShownToUser: text("reason_shown_to_user"),
    searchField: tsvector("search_field", {
      sources: [
        ["scenarios.title", "A"],
        ["scenarios.title", "A"],
        ["scenarios.character_descriptions", "A"],
        ["scenarios.intro_text", "B"],
        ["scenarios.intro_text", "B"],
        ["scenarios.tags", "B"],
        ["scenarios.call_to_action", "B"],
        ["scenarios.tagline", "B"],
        ["scenarios.goal_points", "D"],
        ["scenarios.background", "D"],
      ],
    }),
  },
  (table) => ({
    creatorIndex: index("scenarios_creator_idx").on(table.creatorId),
  }),
);
export const userScenarioFavorites = pgTable("user_scenario_favorites", {
  id: bigserial("id", { mode: "number" }).primaryKey(),
  scenarioId: integer("scenario_id").references(() => scenarios.id),
  userId: integer("user_id").references(() => users.id),
});
export const userCharacterFavorites = pgTable(
  "user_character_favorites",
  {
    id: bigserial("id", { mode: "number" }),
    characterId: integer("character_id").references(() => characters.id),
    userId: integer("user_id").references(() => users.id),
    isFavorite: boolean("is_favorite").notNull(),
  },
  (table) => ({
    pk: primaryKey(table.userId, table.characterId),
  }),
);
export const userSubscriptions = pgTable("user_subscriptions", {
  id: bigserial("id", { mode: "number" }),
  userId: integer("user_id")
    .references(() => users.id)
    .unique(),
  subscriptionId: text("subscription_id"),
  testMode: boolean("test_mode"),
  validUntil: timestamp("valid_until"),
  tier: subscriptionTierEnum("tier"),
});
export const userCharacterFavoritesRelations = relations(
  userCharacterFavorites,
  ({ one, many }) => ({
    character: one(characters, {
      fields: [userCharacterFavorites.characterId],
      references: [characters.id],
    }),
    user: one(users, {
      fields: [userCharacterFavorites.userId],
      references: [users.id],
    }),
  }),
);
export const scenarioSlots = pgTable(
  "scenario_slots",
  {
    id: bigserial("id", { mode: "number" }).primaryKey(),
    scenarioId: integer("scenario_id").references(() => scenarios.id),
    defaultCharacterId: integer("default_character_id").references(
      () => characters.id,
    ),
    name: text("name"),
    description: text("description"),
    background: text("background"),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at"),
  },
  (table) => ({
    scenarioId: index("scenario_idx").on(table.scenarioId),
  }),
);
export const scenarioSlotsRelations = relations(
  scenarioSlots,
  ({ one, many }) => ({
    scenario: one(scenarios, {
      fields: [scenarioSlots.scenarioId],
      references: [scenarios.id],
    }),
    defaultCharacter: one(characters, {
      fields: [scenarioSlots.defaultCharacterId],
      references: [characters.id],
    }),
    conversationCharacterSlots: many(conversationCharacterSlots), // New relation added
  }),
);

export const scenariosRelations = relations(scenarios, ({ many }) => ({
  slots: many(scenarioSlots),
  conversations: many(conversations),
  scenariosToCollections: many(scenarioCollectionsToScenarios),
}));
export const lemonWebhooks = pgTable("lemon_webhooks", {
  id: bigserial("id", { mode: "number" }).primaryKey(),
  lemonWebhookId: text("lemon_webhook_id").notNull(),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  success: boolean("success").notNull().default(false),
  error: text("error"),
  payload: jsonb("payload").$type<DiscriminatedWebhookPayload<any>>(),
  response: jsonb("response").$type<any>(),
});
export const characters = pgTable(
  "character",
  {
    id: bigserial("id", { mode: "number" }).primaryKey(),
    creatorId: integer("creator_id").references(() => users.id),
    age: integer("age"),
    preferredName: text("preferred_name").notNull(),
    avatarImage: text("avatar_image").notNull(),
    avatarThumbnailImage: text("avatar_thumbnail_image").notNull(),
    firstName: text("first_name"),
    lastName: text("last_name"),
    embedding: halfvec("character_embedding_short", { dimensions: 256 }),
    fullEmbedding: halfvec("character_full_embedding_short", {
      dimensions: 256,
    }),
    description: text("description").notNull(),
    occupation: text("occupation"),
    taglines: text("taglines").array(),
    nickNames: text("nick_names").array(),
    backstory: text("backstory").array(),
    memories: text("memories").array(),
    universeDescription: text("universe_description"),
    universeTags: text("universe_tags").array(),
    gender: genderEnum("gender"),
    searchTerms: text("search_terms"),
    summary: text("summary"),
    characterMotivation: text("characterMotivation"),
    sex: sexEnum("sex"),
    isAdultAllowed: boolean("is_adult_allowed"),
    visibility: visibilityEnum("visibility").default("public"),
    isAdultCharacter: boolean("is_adult_character").notNull().default(false),
    speakingStyle: text("speaking_style"),
    speakingExamples: text("speaking_examples").array(),
    personalityTraits: text("personality_traits").array(),
    personalityDescription: text("personality_description"),
    intimacyStyle: text("intimacy_style"),
    visualDescription: text("visual_description"),
    adultVisualDescription: text("adult_visual_description"),
    isCelebrity: boolean("is_celebrity"),
    passesModeration: boolean("passes_moderation"),
    isAnimal: boolean("is_animal"),
    isPersona: boolean("is_persona"),
    isPornographic: boolean("is_pornographic"),
    isGrosslyUnderage: boolean("is_grossly_underage"),
    isDisgusting: boolean("is_disgusting"),
    externalSourceId: text("external_source_id"),
    externalSourceUrl: text("external_source_url"),

    //TODO: Set default values for scenarios
    scenarioSeedIntimacy: text("scenario_seed_intimacy"),
    scenarioSeedCounsel: text("scenario_seed_counsel"),
    scenarioSeedConfrontation: text("scenario_seed_confrontation"),
    scenarioSeedAdventure: text("scenario_seed_adventure"),
    categories: text("categories").array(),
    tags: text("tags").array(),
    totalWordCount: integer("total_word_count").default(0),
    imageContentDescription: text("image_content_description"),
    altText: text("avatar_alt_text"),
    ageEstimate: integer("age_estimate"),
    hasGraphicViolence: boolean("has_graphic_violence"),
    isUnderage: boolean("is_underage"),
    isUnsettling: boolean("is_unsettling"),
    isHatePromotion: boolean("is_hate_promotion"),
    isCustom: boolean("is_custom").default(false),
    blockSearch: boolean("block_search").default(false),
    isSexual: boolean("is_sexual"),
    isSuitable: boolean("is_suitable"),
    isUnsafe: boolean("is_unsafe"),
    reasonShownToUser: text("reason_shown_to_user"),
    generatedTags: text("generated_tags").array(),
    internalSourceCharacterId: bigserial("internal_source_character_id", {
      mode: "number",
    }).references((): AnyPgColumn => characters.id),
    categoryId: text("category_id")
      .notNull()
      // .$type<CharacterCategory>()  TODO: Update values
      .default("CUSTOM")
      .references(() => characterCategories.id),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at"),
    searchField: tsvector("search_field", {
      sources: [
        ["character.preferred_name", "A"],
        ["character.preferred_name", "A"],
        ["character.preferred_name", "A"],
        ["character.first_name", "B"],
        ["character.last_name", "B"],
        ["character.tags", "C"],
        ["character.visual_description", "D"],
        ["character.universe_description", "D"],
        ["character.occupation", "D"],
      ],
    }),
  },
  (table) => ({
    creatorIndex: index("character_creator_idx").on(table.creatorId),
    embeddingIndex: index("character_embedding_idx").using(
      "hnsw",
      table.embedding.op("halfvec_cosine_ops"),
    ),
    fullEmbeddingIndex: index("character_full_embedding_idx").using(
      "hnsw",
      table.fullEmbedding.op("halfvec_cosine_ops"),
    ),
  }),
);

export const messageDeleteReasonEnum = pgEnum(
  "conversation_message_deletion_reason",
  ["regenerated", "filtered", "user"],
);
export const characterCategories = pgTable("character_categories", {
  id: text("id").primaryKey(),
  tags: text("tags").array(),
  friendlyTitle: text("friendly_title"),
  title: text("title"),
  description: text("description"),
  previewImage: text("preview_image"),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

const characterCategoriesRelations = relations(
  characterCategories,
  ({ many }) => ({
    characters: many(characters),
  }),
);

export const messages = pgTable(
  "messages",
  {
    embedding: vector("message_embedding", { dimensions: 768 }),
    embeddingV2: vector("message_embedding_v2", { dimensions: 3072 }),
    id: bigserial("id", { mode: "number" }).primaryKey(),
    conversationId: integer("conversation_id")
      .notNull()
      .references(() => conversations.id),
    content: text("content").array(),
    modelId: text("model_id"),
    creatorId: integer("creator_id")
      .notNull()
      .references(() => users.id),
    personaId: integer("persona_id").references(() => userPersonas.id),
    deleteReason: messageDeleteReasonEnum("delete_reason"),
    instructions: jsonb("jsonb").$type<NarrativeInstruction[]>(),
    rawResponse: jsonb("raw_response").$type<any>(),
    critique: text("critique"),
    isRemix: boolean("is_remix").default(false),
    inputTokens: integer("input_tokens"),
    outputTokens: integer("output_tokens"),
    userVote: voteEnum("user_vote"),
    userBranched: boolean("user_branched").default(false),
    nextSiblingId: integer("next_sibling_id").references(
      (): AnyPgColumn => messages.id,
    ),
    previousSiblingId: integer("previous_sibling_id").references(
      (): AnyPgColumn => messages.id,
    ),
    activeChildId: integer("active_child_id").references(
      (): AnyPgColumn => messages.id,
    ),
    parentId: integer("parent_id").references((): AnyPgColumn => messages.id),
    deleteJustification: text("delete_justification"),
    sceneDescription: text("scene_description"),
    locationDescription: text("location_description"),
    continuationOptions: text("continuation_options").array(),
    createdAt: timestamp("created_at").notNull().defaultNow(),
    updatedAt: timestamp("updated_at").defaultNow(),
    aiGenerated: boolean("ai_generated").notNull(),
    visibleInstructions: text("visible_instructions"), // Shown to the user isntead of instructions when present
    supercharged: boolean("supercharged").default(false),
    attachedImage: text("attached_image"),
    attachedImageAlt: text("attached_image_alt"),
    compactionId: integer("compaction_id").references(() => compactions.id),
    wordCount: integer("word_count"),
    moderationToxic: real("moderation_toxic"),
    moderationInsult: real("moderation_insult"),
    moderationProfanity: real("moderation_profanity"),
    moderationDerogatory: real("moderation_derogatory"),
    moderationSexual: real("moderation_sexual"),
    moderationDeathHarmTragedy: real("moderation_death_harm_tragedy", {}),
    moderationViolent: real("moderation_violent"),
    moderationFirearmsWeapons: real("moderation_firearms_weapons"),
    moderationPublicSafety: real("moderation_public_safety"),
    moderationHealth: real("moderation_health"),
    moderationReligionBelief: real("moderation_religion_belief"),
    moderationIllicitDrugs: real("moderation_illicit_drugs"),
    moderationWarConflict: real("moderation_war_conflict"),
    moderationPolitics: real("moderation_politics"),
    moderationFinance: real("moderation_finance"),
    moderationLegal: real("moderation_legal"),
    moderationCsam: real("moderation_csam"),
    moderationSelfHarm: real("moderation_self_harm"),
    moderationSelfHarmInstructions: real("moderation_self_harm_instructions"),
    moderationSelfHarmIntent: real("moderation_self_harm_intent"),
    branchName: text("branch_name"),
    commitHash: text("commit_hash"),
    llmRequestId: integer("llm_request_id").references(
      (): AnyPgColumn => llmRequests.id,
    ),
  },
  (table) => ({
    compactionIndex: index("compaction_idx").on(table.compactionId),
    conversationIndex: index("conversation_idx").on(table.conversationId),
    embeddingIndex: index("embeddingIndex").using(
      "hnsw",
      table.embeddingV2.op("vector_cosine_ops"),
    ),
    nextSiblingIndex: index("next_sibling_idx").on(table.nextSiblingId),
    previousSiblingIndex: index("previous_sibling_idx").on(
      table.previousSiblingId,
    ),
    activeChildIndex: index("active_child_idx").on(table.activeChildId),
    parentIndex: index("parent_idx").on(table.parentId),
  }),
);

export const messagesRelations = relations(messages, ({ one }) => ({
  conversation: one(conversations, {
    fields: [messages.conversationId],
    references: [conversations.id],
  }),
  compaction: one(compactions, {
    fields: [messages.compactionId],
    references: [compactions.id],
  }),
  nextSibling: one(messages, {
    fields: [messages.nextSiblingId],
    references: [messages.id],
  }),
  previousSibling: one(messages, {
    fields: [messages.previousSiblingId],
    references: [messages.id],
  }),
}));

export const scenarioCollections = pgTable("scenario_collections", {
  id: serial("id").primaryKey(),
  title: text("title"),
  slug: text("slug"),
  description: text("description"),
  creatorId: integer("creator_id")
    .notNull()
    .references(() => users.id),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});
export const userPersonas = pgTable("user_personas", {
  id: serial("id").primaryKey(),
  age: integer("age"),
  gender: genderEnum("gender"),
  sex: sexEnum("sex"),
  preferredName: text("preferred_name"),
  summary: text("summary"),
  details: text("details"),
  avatarImageSrc: text("avatar_image_src"),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
  ownerId: integer("owner_id")
    .notNull()
    .references(() => users.id),
});

export const userPersonasRelations = relations(userPersonas, ({ one }) => ({
  owner: one(users, {
    fields: [userPersonas.ownerId],
    references: [users.id],
  }),
}));

export const scenarioCollectionsRelations = relations(
  scenarioCollections,
  ({ many }) => ({
    scenariosToCollections: many(scenarioCollectionsToScenarios),
  }),
);

export const scenarioCollectionsToScenarios = pgTable(
  "scenariocollections_to_scenarios",
  {
    collectionId: integer("collection_id")
      .notNull()
      .references(() => scenarioCollections.id),
    scenarioId: integer("scenario_id")
      .notNull()
      .references(() => scenarios.id),
  },
  (t) => ({
    pk: primaryKey(t.collectionId, t.scenarioId),
  }),
);
export const scenarioCollectionsToScenariosRelations = relations(
  scenarioCollectionsToScenarios,
  ({ one }) => ({
    collection: one(scenarioCollections, {
      fields: [scenarioCollectionsToScenarios.collectionId],
      references: [scenarioCollections.id],
    }),
    scenario: one(scenarios, {
      fields: [scenarioCollectionsToScenarios.scenarioId],
      references: [scenarios.id],
    }),
  }),
);
export const sharedConversations = pgTable("shared_conversations", {
  id: text("id").notNull().unique(),
  conversationId: integer("conversation_id")
    .notNull()
    .references(() => conversations.id),
  messages: integer("message_ids").array().notNull(),
  previewImage: text("preview_image"),
  title: text("title"),
  viewCount: integer("view_count").notNull().default(0),
  viewIps: text("view_ips").notNull().array(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});
export const sharedConversationsRelations = relations(
  sharedConversations,
  ({ one }) => ({
    conversation: one(conversations, {
      fields: [sharedConversations.conversationId],
      references: [conversations.id],
    }),
  }),
);
export const charactersToScenarioSlots = pgTable(
  "characters_to_scenario_slots",
  {
    characterId: integer("character_id")
      .notNull()
      .references(() => characters.id),
    scenarioSlotId: integer("slot_id")
      .notNull()
      .references(() => scenarioSlots.id),
  },
  (t) => ({
    pk: primaryKey(t.characterId, t.scenarioSlotId),
  }),
);
export const charactersToScenarioSlotsRelations = relations(
  charactersToScenarioSlots,
  ({ one }) => ({
    character: one(characters, {
      fields: [charactersToScenarioSlots.characterId],
      references: [characters.id],
    }),
    scenarioSlot: one(scenarioSlots, {
      fields: [charactersToScenarioSlots.scenarioSlotId],
      references: [scenarioSlots.id],
    }),
  }),
);
export const charactersRelations = relations(characters, ({ many, one }) => ({
  charactersToScenarioSlots: many(charactersToScenarioSlots),
  charactersToCharacterFavorites: many(userCharacterFavorites),
  conversationCharacterSlots: many(conversationCharacterSlots),
  creator: one(users, {
    fields: [characters.creatorId],
    references: [users.id],
  }),
  category: one(characterCategories, {
    fields: [characters.categoryId],
    references: [characterCategories.id],
  }),
}));

export const conversationCharacterSlots = pgTable(
  "conversation_character_slots",
  {
    id: serial("id").primaryKey(),
    conversationId: integer("conversation_id")
      .notNull()
      .references(() => conversations.id, { onDelete: "cascade" }),
    characterId: integer("character_id")
      .notNull()
      .references(() => characters.id),
    scenarioSlotId: integer("scenario_slot_id").references(
      () => scenarioSlots.id,
    ),
    isPresent: boolean("is_present").notNull().default(true),
    controllerId: integer("controller_id").references(() => users.id),
  },
  (table) => ({
    conversationIdIndex: index(
      "conversation_character_slot_conversation_idx",
    ).on(table.conversationId),
    scenarioSlotIdIndex: index(
      "conversation_character_slot_scenario_slot_idx",
    ).on(table.scenarioSlotId),
    characterIdIndex: index("conversation_character_slot_character_idx").on(
      table.characterId,
    ),
    controller_id_IdIndex: index(
      "conversation_character_slot_controller_idx",
    ).on(table.controllerId),
  }),
);
export const conversationCharacterSlotsRelations = relations(
  conversationCharacterSlots,
  ({ one }) => ({
    conversation: one(conversations, {
      fields: [conversationCharacterSlots.conversationId],
      references: [conversations.id],
    }),
    scenarioSlot: one(scenarioSlots, {
      fields: [conversationCharacterSlots.scenarioSlotId],
      references: [scenarioSlots.id],
    }),
    controller: one(users, {
      fields: [conversationCharacterSlots.controllerId],
      references: [users.id],
    }),
    character: one(characters, {
      fields: [conversationCharacterSlots.characterId],
      references: [characters.id],
    }),
  }),
);
export const conversationsRelations = relations(
  conversations,
  ({ many, one }) => ({
    messages: many(messages),
    compactions: many(compactions),
    creator: one(users, {
      fields: [conversations.creatorId],
      references: [users.id],
    }),
    selectedPersona: one(userPersonas, {
      fields: [conversations.selectedPersonaId],
      references: [userPersonas.id],
    }),
    characterSlots: many(conversationCharacterSlots),
    scenario: one(scenarios, {
      fields: [conversations.scenarioId],
      references: [scenarios.id],
    }),
  }),
);
export const compactions = pgTable(
  "message_compactions",
  {
    id: bigserial("id", { mode: "bigint" }).primaryKey(),
    conversationId: integer("conversation_id")
      .notNull()
      .references(() => conversations.id),
    summary: text("summary"),
    aiContext: text("ai_context"),
    elapsedTime: text("elapsed_time"),
    createdAt: timestamp("created_at").defaultNow().notNull(),
    updatedAt: timestamp("updated_at").defaultNow(),
  },
  (table) => ({
    conversationIndex: index("conversation_compaction_idx").on(
      table.conversationId,
    ),
  }),
);

export const compactionsRelations = relations(compactions, ({ many, one }) => ({
  messages: many(messages),
  conversation: one(conversations, {
    fields: [compactions.conversationId],
    references: [conversations.id],
  }),
}));
export const userReferral = pgTable("user_referral", {
  id: serial("id").primaryKey(),
  referredUserId: integer("referred_user_id")
    .references(() => users.id)
    .unique()
    .notNull(),
  referredByUserId: integer("referred_by_user_id")
    .references(() => users.id)
    .notNull(),
  quillsGranted: boolean("quills_granted").notNull().default(false),
  createdAt: timestamp("created_at").notNull().defaultNow(),
});
export const llmRequests = pgTable("llm_requests", {
  id: serial("id").primaryKey(),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  modelId: text("model_id").notNull(),
  provider: text("provider").notNull(),
  inputTokens: integer("input_tokens").notNull(),
  outputTokens: integer("output_tokens").notNull(),
  promptStoragePath: text("prompt_storage_path"),
  costEstimate: real("cost_estimate"),
  generationReason: text("generation_reason").notNull(),
  gitBranch: text("git_branch"),
  gitCommitHash: text("git_commit_hash"),
  messageId: integer("message_id").references((): AnyPgColumn => messages.id),
});
export const userReferralRelations = relations(userReferral, ({ one }) => ({
  referredUser: one(users, {
    fields: [userReferral.referredUserId],
    references: [users.id],
    relationName: "referredUser",
  }),
  referredByUser: one(users, {
    fields: [userReferral.referredByUserId],
    references: [users.id],
    relationName: "referredByUser",
  }),
}));
export const userCharacters = pgTable("user_character", {
  id: serial("id").primaryKey(),
  preferredName: text("preferred_name"),
  gender: genderEnum("gender"), // Assuming genderEnum is a custom enum type you've defined
  sex: sexEnum("sex"), // Assuming sexEnum is a custom enum type you've defined
  visualDescription: text("visual_description"),
  avatarImageSrc: text("avatar_image_src"),
  creatorId: integer("creator_id")
    .references(() => users.id)
    .default(1),
  characterId: bigserial("character_id", { mode: "number" }).references(
    () => characters.id,
  ),
  personalityDescription: text("personality_description"),
  universeDescription: text("universe_description"),
  briefDescription: text("brief_description"),
  speakingDescription: text("speaking_description"),
  age: integer("age"), // Assuming age is an integer value
  createdAt: timestamp("created_at").notNull().defaultNow(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const userCharactersRelations = relations(userCharacters, ({ one }) => ({
  sourceCharacter: one(characters, {
    fields: [userCharacters.characterId],
    references: [characters.id],
  }),
}));

export const characterHistoricalStats = pgMaterializedView('character_trending_scores')
.as((qb) => {
  // First calculate historical daily counts
  const dailyCounts = qb.$with('daily_counts').as(
    qb.select({
      characterId: conversationCharacterSlots.characterId,
      dailyCount: sql<number>`COUNT(DISTINCT ${messages.conversationId})`.as('daily_count'),
      createdDate: sql<Date>`DATE(${messages.createdAt})`.as('created_date')
    })
    .from(messages)
    .where(
      and(
        gte(messages.createdAt, sql`CURRENT_DATE - INTERVAL '30 days'`),
        lt(messages.createdAt, sql`CURRENT_DATE - INTERVAL '4 days'`)
      )
    )
    .leftJoin(
      conversationCharacterSlots, 
      eq(conversationCharacterSlots.conversationId, messages.conversationId)
    )
    .groupBy(conversationCharacterSlots.characterId, sql`DATE(${messages.createdAt})`)
  );

  // Calculate recent message counts
  const recentMessageCount = qb.$with('recent_message_count').as(
    qb.select({
      recentCount: sql<number>`COUNT(DISTINCT ${messages.conversationId})`.as('recent_count'),
      characterId: conversationCharacterSlots.characterId
    })
    .from(messages)
    .innerJoin(
      conversationCharacterSlots,
      eq(conversationCharacterSlots.conversationId, messages.conversationId)
    )
    .where(
      and(
        gte(messages.createdAt, sql`CURRENT_DATE - INTERVAL '4 days'`),
        lt(messages.createdAt, sql`CURRENT_DATE`)
      )
    )
    .groupBy(conversationCharacterSlots.characterId)
  );

  // Final trending score calculation
  const trendingScore = qb
    .with(dailyCounts, recentMessageCount)
    .select({
      characterId: characters.id,
      trendingScore: sql<number>`
        CASE
          WHEN COALESCE(STDDEV(${dailyCounts.dailyCount}), 0) > 0 THEN
            (COALESCE(${recentMessageCount.recentCount}, 0) - COALESCE(AVG(${dailyCounts.dailyCount}), 0))
            / STDDEV(${dailyCounts.dailyCount})
          WHEN COALESCE(AVG(${dailyCounts.dailyCount}), 0) = 0 AND COALESCE(${recentMessageCount.recentCount}, 0) > 0 THEN
            3
          WHEN COALESCE(AVG(${dailyCounts.dailyCount}), 0) > 0 AND COALESCE(${recentMessageCount.recentCount}, 0) = 0 THEN
            -3
          ELSE
            0
        END
      `.as('trending_score'),
      avgMessageCount: sql<number>`COALESCE(AVG(${dailyCounts.dailyCount}), 0)`.as('avg_message_count'),
      stdDevMessageCount: sql<number>`COALESCE(STDDEV(${dailyCounts.dailyCount}), 0)`.as('stddev_message_count'),
      recentMessageCount: sql<number>`COALESCE(${recentMessageCount.recentCount}, 0)`.as('recent_message_count')
    })
    .from(characters)
    .leftJoin(dailyCounts, eq(dailyCounts.characterId, characters.id))
    .leftJoin(recentMessageCount, eq(recentMessageCount.characterId, characters.id))
    .groupBy(characters.id, recentMessageCount.recentCount);

    return trendingScore;
});


export const scenarioWordCounts = pgMaterializedView('scenario_word_counts')
.as((qb) => {
  return qb
    .select({
      scenarioId: conversations.scenarioId,
      totalWordCount: sql<number>`SUM(${messages.wordCount})`.as('scenario_total_word_count')
    })
    .from(conversations)
    .innerJoin(messages, eq(messages.conversationId, conversations.id))
    .groupBy(conversations.scenarioId)
});

export const lemonOrders = pgTable("lemon_orders", {
  id: bigint("id", { mode: "number" }).primaryKey(),
  amount: doublePrecision("amount"),
  date: timestamp("date"),
  email: text("email"),
  isRenewal: boolean("is_renewal"),
  productId: bigint("product_id", { mode: "number" }),
  purchaseType: text("purchase_type"),
  status: text("status"),
  variantId: bigint("variant_id", { mode: "number" }),
  createdAt: timestamp("created_at").notNull().defaultNow()
});