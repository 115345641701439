import { ExistingCharacter } from "@/db/enumTypes";
import { titleCase } from "title-case";
export interface FrontendCharacter {
  id: number;
  name?: string | null;
  description?: string | null;
  wordCount?: number | null;
  traits: string[];
  avatarUrl: string;
  isPrivate: boolean;
  altText: string;
  sourceUrl: string | null;
  avatarUrlHd: string;
  isFavorite?: boolean | null;
  isAdult?: boolean | null;
  isCustom?: boolean | null;
  creatorId?: string | null;
  backstory: string;
  universe: string;
  fullDescription: string;
}

// Use with drizzle to filter needed columns only
export const frontendCharacterColumns = {
  id: true,
  preferredName: true,
  personalityTraits: true,
  avatarImage: true,
  altText: true,
  isAdult: true,
  passesModeration: true,
  summary: true,
  backstory: true,
  description: true,
  wordCount: true,
  externalSourceUrl: true,
  isFavorite: true,
  characterUniverse: true,
  totalWordCount: true,
  visibility: true
};
export function toFrontendCharacter(item: Partial<ExistingCharacter>, isFavorite?: boolean, wordCount?: number): FrontendCharacter {
  //Convert universe to title case
  const characterUniverse = titleCase(item?.universeTags?.[0] ?? "");
  return {
    id: item.id,
    name: item!.preferredName!,
    traits: item.personalityTraits as string[],
    avatarUrl: item.avatarImage + "?tr=w-250:q-60",
    avatarUrlHd: item.avatarImage!,
    altText: item.altText!,
    isAdult: item.isAdultCharacter || !item.passesModeration,
    isCustom: item.isCustom,
    creatorId: item.creatorId,
    description: item.summary ?? item.description,
    backstory: item.backstory,
    fullDescription: item.description,
    wordCount: item.totalWordCount,
    sourceUrl: item.externalSourceUrl,
    isFavorite: isFavorite,
    universe: characterUniverse,
    isPrivate: item.visibility === "private"
  };
}