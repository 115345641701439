import { audioCost } from "@/pages/api/trpc/messages/quillCosts";
import { monthlyQuillCount } from "@/pages/api/webhooks/monthlyQuillCount";
import { useUserProfile } from "@/utils/auth/useUserProfile";
import { trpc } from "@/utils/trpc";
import { Button, Dialog, Text, Flex, Heading, Box, Link } from "@radix-ui/themes";
import { useState } from "react";
export function SubscriptionDialog({
  trigger
}: {
  trigger: React.ReactNode;
}) {
  const [showDialog, setShowDialog] = useState(false);
  const {
    userProfile
  } = useUserProfile();
  const billingPortal = trpc.users.getUserBillingLink.useQuery({}, {
    enabled: !!userProfile
  });
  return <Dialog.Root open={showDialog} onOpenChange={setShowDialog} data-sentry-element="unknown" data-sentry-component="SubscriptionDialog" data-sentry-source-file="subscriptionDialog.tsx">
      <Dialog.Trigger data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">{trigger}</Dialog.Trigger>
      <Dialog.Content data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">
        <Dialog.Title data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">
          <Heading data-sentry-element="Heading" data-sentry-source-file="subscriptionDialog.tsx">Manage Quills</Heading>
        </Dialog.Title>
        <Dialog.Description data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">
          <Text data-sentry-element="Text" data-sentry-source-file="subscriptionDialog.tsx">
            Quills are the optional currency of the realm. You can use them to
            send messages, regenerate messages, and narrate messages.
            <br />
            <br />
            <b>
              YOU DO NOT NEED QUILLS TO USE THE SITE, YOU CAN STILL WRITE
              STORIES WHEN YOU RUN OUT OF QUILLS
            </b>
            <br />
            <br />
            When you run out of quills, you can purchase or earn more. Without
            quills your story will be downgraded.
            <br />
            <br />
            <b>
              Without quills you can still write, regenerate and more as much as
              you want.
            </b>{" "}
            but you'll use a different AI model that's slower, a few beta
            features, and no longer have access to narrated messages
            <br />
            <br />
          </Text>
        </Dialog.Description>

        <Flex direction="column" gap="3" py="2" data-sentry-element="Flex" data-sentry-source-file="subscriptionDialog.tsx">
          <Text data-sentry-element="Text" data-sentry-source-file="subscriptionDialog.tsx">
            <b>
              You have <u>{userProfile?.quills}</u> quills
            </b>
          </Text>
          <Box data-sentry-element="Box" data-sentry-source-file="subscriptionDialog.tsx">
            <ul className="list-disc list-inside">
              <li>
                Sending a message costs <b>5</b> quills, or <b>0</b> quills if
                you downgrade to our free tier
              </li>
              <li>
                Extra characters after your <b>4th</b> character cost <b>1</b>{" "}
                additional quill per character for our premium tier
              </li>
              <li>
                Regenerating a message costs <b>1</b> quill or <b>0</b> quills
                if you downgrade to our free tier
              </li>
              <li>
                Narrating a message costs <b>{audioCost}</b> quills, or{" "}
                <b>{audioCost * 2}</b> quills if you supercharge your message.
                You cannot narrate messages if you downgrade to our free tier
              </li>
            </ul>
          </Box>
          <Heading size="3" data-sentry-element="Heading" data-sentry-source-file="subscriptionDialog.tsx">Getting more quills</Heading>
          <Box py="2" data-sentry-element="Box" data-sentry-source-file="subscriptionDialog.tsx">
            <ul className="list-disc list-inside">
              <li>
                <b>YOU DO NOT NEED QUILLS TO USE THE SITE</b>
              </li>
              <li>You must subscribe or refer users to get new quills</li>
              <li>
                Subscribers get <b>{monthlyQuillCount}</b> quills every month,
                which is enough to generate over <i>600 paragraphs</i> every
                month. (These don't stack up, so use them!)
              </li>
              <li>
                You can purchase more quills{" "}
                <b>
                  <Link href={billingPortal.data?.url} data-sentry-element="Link" data-sentry-source-file="subscriptionDialog.tsx">here</Link>
                </b>
              </li>
            </ul>
          </Box>
          <Button variant="surface" loading={billingPortal.isLoading} onClick={() => {
          billingPortal.data?.url && window.open(billingPortal.data.url, "_blank");
        }} data-sentry-element="Button" data-sentry-source-file="subscriptionDialog.tsx">
            Access your subscription
          </Button>
          <Dialog.Close data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">
            <Button variant="surface" color="red" data-sentry-element="Button" data-sentry-source-file="subscriptionDialog.tsx">
              Close
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>;
}