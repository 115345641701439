// Import React
import React from "react";
// Import the CSS module
import styles from "./signInButton.module.css";
import { useSupabaseClient } from "@/hooks/useUser";
import { SignInWithApple } from "@selaliadobor/apple-sign-in";
import { toast } from "sonner";
import { Sha256 } from "@aws-crypto/sha256-browser";
import { FaApple } from "react-icons/fa6";
let options = {
  clientId: "com.tryspellbound",
  //redirectURI: "https://auth.tryspellbound.com/auth/v1/callback",
  scopes: "email",
  redirectURI: "https://owpjypejugxqapedtrra.supabase.co/auth/v1/callback",
  state: "12345"
};
function SignInWithAppleId({
  redirect
}: {
  redirect?: string;
}) {
  const supabase = useSupabaseClient();
  const finalRedirect = redirect ?? "/app/home";
  return <button className={styles.gsiMaterialButton} style={{
    width: "100%"
  }} onClick={async () => {
    console.log("options", options);
    toast("Signing in with Apple");
    const nonce = Math.random().toString(36).substring(2, 15);
    const hash = new Sha256();
    hash.update(nonce);
    const nonceRawHash = await hash.digest();
    let nonceHash = Buffer.from(nonceRawHash).toString("hex");
    SignInWithApple.authorize({
      ...options,
      nonce: nonceHash
    }).then(result => {
      supabase.auth.signInWithIdToken({
        provider: "apple",
        token: result.response.identityToken,
        nonce: nonce
      }).then(rest => {
        toast.success("Signed in with Apple");
        console.log("Signed in with Apple", rest);
      }).catch(error => {
        toast.error("Error signing in with Supabase Apple: " + error.message);
        console.error("Supabase error:", error);
      });
    }).catch(error => {
      toast.error("Error signing in with Apple: " + error.message);
      console.error("Apple Sign In error:", error);
    });
  }} data-sentry-component="SignInWithAppleId" data-sentry-source-file="signInWithApple.tsx">
      <div className={styles.gsiMaterialButtonState}></div>
      <div className={styles.gsiMaterialButtonContentWrapper}>
        <div className={styles.gsiMaterialButtonIcon}>
          <FaApple data-sentry-element="FaApple" data-sentry-source-file="signInWithApple.tsx" />
        </div>
        <span className={styles.gsiMaterialButtonContents}>
          Continue with Apple
        </span>
        <span style={{
        display: "none"
      }}>Continue with Apple</span>
      </div>
    </button>;
}
export default SignInWithAppleId;