import { FrontendCharacter } from "../../../../app/home/frontendCharacter";
import { Box } from "@radix-ui/themes";
import Image from "next/image";
import { imageKitLoader } from "@/pages/app/home/imageKitLoader";
import { useRouterUtils } from "@/app/routerUtils";
export function CharacterAvatar({
  character,
  index,
  loadEarly,
  size = "180px",
  enableShortcut = false
}: {
  index: any;
  character: FrontendCharacter;
  loadEarly: boolean;
  size?: string;
  enableShortcut?: boolean;
}) {
  const {
    openCharacter
  } = useRouterUtils();
  return <Box style={{
    position: "absolute",
    bottom: "43%",
    left: `calc(.5 * ${size} * ${index})`,
    zIndex: 8 - index,
    width: size,
    height: size
  }} className={`object-cover object-top `} data-sentry-element="Box" data-sentry-component="CharacterAvatar" data-sentry-source-file="characterAvatar.tsx">
      <Box className={"relative w-full h-full"} data-sentry-element="Box" data-sentry-source-file="characterAvatar.tsx">
        <Image alt={character.backstory} loader={imageKitLoader} onClick={e => {
        if (enableShortcut) {
          openCharacter(character.id);
          e.stopPropagation();
          e.preventDefault();
        }
      }} loading={loadEarly ? "eager" : "lazy"} fill style={{
        paddingRight: `calc(.3 * ${size})`,
        paddingTop: "1.5rem",
        borderRadius: "999999px",
        overflow: "hidden",
        WebkitFilter: "drop-shadow(0px 5px 2px #000000)",
        filter: "drop-shadow(0px 5px 2px #000000)",
        objectFit: "cover",
        objectPosition: "top"
      }} className={enableShortcut ? "hover:scale-[103%] transition-transform duration-300 ease-in-out" : undefined} sizes="200px, (max-width: 768px) 300px, (max-width: 1200px) 300px" src={character.avatarUrlHd} data-sentry-element="Image" data-sentry-source-file="characterAvatar.tsx" />
      </Box>
    </Box>;
}