import { FrontendScenario } from "@/backend/frontendScenario";
import { CharacterAvatar } from "@/pages/app/home/characterAvatar";
export function CharacterAvatarRow({
  scenario,
  loadEarly,
  size,
  enableShortcut = false
}: {
  scenario: FrontendScenario;
  loadEarly: boolean;
  size: string;
  enableShortcut?: boolean;
}) {
  return <>
      {scenario.characters.map((character, index) => <CharacterAvatar size={size} key={character.id} index={index} character={character} loadEarly={loadEarly} enableShortcut={enableShortcut} />)}
    </>;
}