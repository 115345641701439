// Import React
import React, { useState } from "react";
// Import the CSS module
import styles from "./signInButton.module.css";
import { useSupabaseClient } from "@/hooks/useUser";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { toast } from "sonner";
import { getBaseAppUrl } from "@/pages/getBaseAppUrl";
import { LuMail } from "react-icons/lu";
import { Button, Dialog, Flex, TextField, Text } from "@radix-ui/themes";
function SignInWithEmail({
  redirect
}: {
  redirect?: string;
}) {
  const supabase = useSupabaseClient();
  const finalRedirect = redirect ?? "/app/home";
  const isWeb = Capacitor.getPlatform() === "web";
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  return <Dialog.Root open={open} onOpenChange={setOpen} data-sentry-element="unknown" data-sentry-component="SignInWithEmail" data-sentry-source-file="signInWithEmail.tsx">
      <Dialog.Trigger data-sentry-element="unknown" data-sentry-source-file="signInWithEmail.tsx">
        <button className={styles.gsiMaterialButton} style={{
        width: "100%"
      }}>
          <div className={styles.gsiMaterialButtonState}></div>
          <div className={styles.gsiMaterialButtonContentWrapper}>
            <div className={styles.gsiMaterialButtonIcon}>
              <LuMail data-sentry-element="LuMail" data-sentry-source-file="signInWithEmail.tsx" />
            </div>
            <span className={styles.gsiMaterialButtonContents}>
              Continue with Email (Not Recommended)
            </span>
            <span style={{
            display: "none"
          }}>Continue with Email</span>
          </div>
        </button>
      </Dialog.Trigger>
      <Dialog.Content data-sentry-element="unknown" data-sentry-source-file="signInWithEmail.tsx">
        <Dialog.Title data-sentry-element="unknown" data-sentry-source-file="signInWithEmail.tsx">Sign in with email (Not Recommended)</Dialog.Title>
        <Dialog.Description data-sentry-element="unknown" data-sentry-source-file="signInWithEmail.tsx">Enter your email to sign in</Dialog.Description>
        <Text color="red" size="4" data-sentry-element="Text" data-sentry-source-file="signInWithEmail.tsx">
          Warning: We highly recommend using our other sign in methods. <br /> <b>You can lose access to your account at any time if you use this method.</b>
        </Text>
        <Flex direction="column" gap="2" my="2" data-sentry-element="Flex" data-sentry-source-file="signInWithEmail.tsx">
          <TextField.Root placeholder="Your email" value={email} onChange={e => setEmail(e.target.value)} size={"3"} data-sentry-element="unknown" data-sentry-source-file="signInWithEmail.tsx"></TextField.Root>
          <Button size={"3"} onClick={() => {
          supabase.auth.signInWithOtp({
            email: email,
            options: {
              emailRedirectTo: isWeb ? `${window.location.origin}/auth/redirect?actualTarget=${encodeURI(window.location.href)}` : "spellbound://app/home"
            }
          }).then(() => {
            toast.success("A link to login has been sent to your email", {
              position: "top-center"
            });
            setOpen(false);
          });
        }} data-sentry-element="Button" data-sentry-source-file="signInWithEmail.tsx">
            Send a login link
          </Button>
          <Dialog.Close data-sentry-element="unknown" data-sentry-source-file="signInWithEmail.tsx">
            <Button size={"3"} variant="soft" color="gray" data-sentry-element="Button" data-sentry-source-file="signInWithEmail.tsx">
              Cancel
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>;
}
export default SignInWithEmail;